import React, {useEffect, useState} from 'react'
import {graphql}                    from 'gatsby'
import {Barrister}                  from '../../entities/barrister'
import Layout                       from '../components/shared/layout'
import {Grid, Theme}                from '@material-ui/core'
import theme                        from '../theme'
import {makeStyles}                 from '@material-ui/core/styles'
import BarristerProfile             from '../components/BarristerProfile'
import Container                    from '@material-ui/core/Container'
import StructuredData               from '../components/StructuredData'
import SanityImage                  from 'gatsby-plugin-sanity-image'

export const query = graphql`
    query BarristerQuery($id: String!) {
        barrister: sanityBarrister(_id: { eq: $id }) {
            firstName
            lastName
            preferredName
            barristerGroup
            _rawBiography(resolveReferences: {maxDepth: 5})
            _rawPracticeDescription(resolveReferences: {maxDepth: 5})
            cases {
                citation
                link
                decisionFile {
                    asset {
                        url
                    }
                }
            }
            resume {
                asset {
                    url
                }
            }
            barristerPortrait {
                ...PortraitData
            }
            chambers {
                addressOne
                addressTwo
                chambersName
                postcode
                state
            }
            contactDetails {
                email
                fax
                linkedinProfile
                telephone
                twitterHandle
            }
            id
            languages
            practiceAreas {
                commercialLaw {
                    subPracticeAreas
                }
                criminalLaw {
                    subPracticeAreas
                }
                employmentIndustrial {
                    subPracticeAreas
                }
                intellectualProperty {
                    subPracticeAreas
                }
                publicLaw {
                    subPracticeAreas
                }
                tortLaw {
                    subPracticeAreas
                }
                topLevelPracticeArea
            }
            practices {
                admissionToPracticeYear
                directBriefs
                electronicBriefs
                joinedBarYear
                jurisdiction
                silkPostnomial
                silkYear
                takenSilk
            }
            languages
            qualifications
            resume {
                asset {
                    url
                }
            }
            memberships
            slug {
                current
            }
        }
    }
`

interface BarristerProps {
    theme: Theme
    data: { barrister: Barrister }
}

const useStyles = makeStyles((theme: Theme) => ({
    admissionYears: {},
    barristerProfileContainer: {
        backgroundColor: '#FFF',
        padding: theme.spacing(3)
    },
    biography: {},
    practiceDetailsContainer: {},
    keyDetailsSection: {},
    barristerPhotoContainer: {},
    barristerPhoto: {},
    practiceAreasContainer: {},
    practiceAreaList: {},
    practiceAreaItem: {},
    practiceAreaTitle: {},
    nameContainer: {},
    profileSection: {},
    profilePictureSection: {},
    profileImageContainer: {
        background: '#333',
        [theme.breakpoints.down('sm')]: {}
    },

    profileImage: {
        [theme.breakpoints.down('sm')]: {
            minWidth: '320px',
            maxHeight: '850px'
        }
    }
}), {index: 1})

const BarristerProfilePage = (props: BarristerProps) => {
    const classes = useStyles(theme)
    // console.log('Barrister template props: ', props)
    // console.log('Barrister Data: ', props.data.barrister)
    const barrister = props.data.barrister
    const [barristerStructuredData, setBarristerStructuredData] = useState<any>()

    const practiceAreaValues: Record<string, string> = {
        'CRIMINAL_LAW': 'Criminal Law',
        'PUBLIC_LAW': 'Public Law',
        'TORT_LAW': 'Tort Law',
        'INTELLECTUAL_PROPERTY': 'Intellectual Property',
        'EMPLOYMENT_INDUSTRIAL': 'Employment and Industrial Law',
        'COMMERCIAL_LAW': 'Commercial Law'
    }

    useEffect(() => {
            if (barrister) {

                const knowsAbout = barrister.practiceAreas.map((pa, idx) => {
                        return idx === barrister.practiceAreas.length - 1
                               ? `and ${practiceAreaValues[pa.topLevelPracticeArea].trim()}.`
                               : `${practiceAreaValues[pa.topLevelPracticeArea].trim()}`
                    }
                )

                setBarristerStructuredData(
                    {
                        'name': `${barrister.preferredName}`,
                        'description': `Barrister at Level 22 Chambers in Sydney, Australia.`,
                        'identifier': `https://level22.com.au/barristers/${barrister.slug.current}`,
                        'url': `https://level22.com.au/barristers/${barrister.slug.current}`,
                        'image': `${barrister.barristerPortrait.asset.url}`,
                        'parentOrganisation': 'Level 22 Chambers',
                        'knowsAbout': knowsAbout.join(', '),
                        'knowsLanguage': `${barrister.languages.join(', ')}`,
                        'makesOffer': {
                            '@context': 'https://schema.org',
                            '@type': 'Offer',
                            'itemOffered': {
                                '@context': 'https://schema.org',
                                '@type': 'Service',
                                'serviceType': 'Legal Advice',
                                'provider': `${barrister.preferredName} of Level 22 Chambers`,
                                'serviceOutput': 'Legal Advice',
                                'category': 'Legal Services'
                            }
                        },
                        '@context': 'https://schema.org',
                        '@type': 'LocalBusiness',
                        'address': {
                            '@type': 'PostalAddress',
                            'addressLocality': 'Sydney, Australia',
                            'postalCode': '2000',
                            'streetAddress': '52 Martin Place'
                        },
                        'openingHours': [
                            'Mo-Fr 08:30-17:00'
                        ],
                        'contactPoint': {
                            '@context': 'https://schema.org',
                            '@type': 'ContactPoint',
                            'name': `Clerk for ${barrister.preferredName}`,
                            'email': barrister.contactDetails.email,
                            'faxNumber': barrister.contactDetails.fax,
                            'telephone': barrister.contactDetails.telephone,
                            'productSupported': 'Legal advice and representation'
                        },
                        'organisation': 'Level 22 Chambers',
                        'telephone': barrister.contactDetails.telephone
                    }
                )

            }
        },
        [barrister]
    )

    return (
        <Layout helmetData={{
            pageTitle: barrister.preferredName,
            metaDescription: `${barrister.preferredName}, based at Level 22 Chambers in Sydney, Australia, provides practical legal assistance and advice.`,
            slug: `barristers/${barrister.slug.current}`
        }} backgroundImage={''} omitStructuredData={true}>
            <StructuredData data={barristerStructuredData}/>
            <Container maxWidth={'xl'} disableGutters={true}>
                <Grid style={{background: '#FFF'}} container justify={'center'}>
                    <Grid className={classes.profileImageContainer} item sm={12} md={6}>
                        {
                            barrister.barristerPortrait
                            && (
                                <SanityImage
                                    {...barrister.barristerPortrait}
                                    className={classes.profileImage}
                                    style={{
                                        position: 'sticky',
                                        top: '-30px',
                                        width: '100%'
                                    }}
                                    alt={`Photograph of ${barrister.preferredName}`}/>
                            )
                        }
                    </Grid>
                    <Grid className={classes.barristerProfileContainer} item sm={12} md={6}>
                        <BarristerProfile barrister={barrister}/>
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    );
}

export default BarristerProfilePage
