import React, {useState} from 'react'
import {
    Barrister,
    Case,
    ContactDetails,
    Practice,
    PracticeArea
}                        from '../../entities/barrister'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Container,
    Divider,
    Grid,
    Theme,
    Typography
}                        from '@material-ui/core'
import theme             from '../theme'
import {makeStyles}      from '@material-ui/core/styles'
import ExpandMoreIcon    from '@material-ui/icons/ExpandMore'
import CallIcon          from '@material-ui/icons/Call'
import EmailIcon         from '@material-ui/icons/Email'
import TwitterIcon       from '@material-ui/icons/Twitter'
import PictureAsPdfIcon  from '@material-ui/icons/PictureAsPdf'
import LinkedInIcon      from '@material-ui/icons/LinkedIn'
import PortableText      from './portableText'
import {Link}            from 'gatsby'

type SubPracticeArea = { subPracticeAreas: string[] }
type PracticeAreas = {
    'commercialLaw': SubPracticeArea,
    'criminalLaw': SubPracticeArea,
    'employmentIndustrial': SubPracticeArea,
    'intellectualProperty': SubPracticeArea,
    'publicLaw': SubPracticeArea,
    'tortLaw': SubPracticeArea,
}

interface BarristerProfileProps {
    barrister: Barrister
}

const useStyles = makeStyles((theme: Theme) => ({
    practiceAreasAccordion: {
        display: 'block'
    },
    subPracticeAreaContainer: {
        columns: 2
    },
    practiceAreasList: {
        listStyle: 'none',
        columns: 2,
        columnGap: '2rem',
        paddingLeft: 0
    },
    topLevelPracticeArea: {
        fontWeight: 500
    },
    practiceAreaChip: {
        background: '#CBC5BA',
        // border: '4px solid #333',
        color: '#000',
        fontSize: '1.1rem',
        padding: '0.6em',
        fontWeight: 600,
        display: 'grid',
        justifyContent: 'flex-start',
        alignContent: 'center',
        letterSpacing: '0.5px',
        transition: '0.25s ease-in'
        // '&:hover': {
        //   background: '#F2F2F2'
        // }


    },
    practiceWrapper: {
        width: '100%'
    },
    practiceAreasContainer: {},
    biographyParagraph: {
        marginBottom: '1.3em',
        fontSize: '1.4em'
    },
    barristerNameTitle: {
        fontSize: '3.5rem',
        fontWeight: 500
    },
    caseLinks: {
        '& a': {
            textDecoration: 'none',
            color: '#000',
            cursor: 'pointer',
            '&:hover': {
                color: 'goldenrod',
                textDecoration: 'underline'
            }
        }
    },
    caseList: {
        listStyleType: 'none',
        paddingInlineStart: 'unset'
    },
    contactDetailsLink: {
        fontSize: '1rem',
        textDecoration: 'none',
        color: theme.palette.primary.main,
        transition: '0.5s',
        display: 'inline-grid',
        gridTemplateColumns: 'auto auto',
        gridGap: '2rem',
        placeItems: 'center',
        '&:hover': {
            color: 'goldenrod'
        }
    },
    contactDetailsContainer: {
        padding: '1rem 0 0 0'

    },
    barristerInformationContainer: {
        display: 'flex'
    },
    biographyContainer: {
        maxWidth: '600px'
    },
    sideBarContainer: {}
}))

const BarristerProfile = (props: BarristerProfileProps) => {
    const classes = useStyles(theme)
    const {barrister} = props

    const [showOtherPracticeAreas, setShowOtherPracticeAreas] = useState<boolean>(false)
    const handleToggleShowOtherPracticeAreas = () => {
        setShowOtherPracticeAreas(!showOtherPracticeAreas)
    }
    const [sidebarExpanded, setSidebarExpanded] = useState<string | false>('practiceAreas')

    const serialiseBiography = {
        types: {
            strong: (props: any) => (
                <strong>
                    {props.node.strong}
                </strong>
            ),
            mainImage: (props: any) => (
                <div>
                    <img/>
                </div>
            )
        },
        marks: {
            strong: (props: any) => (
                <strong>
                    {props.node.strong}
                </strong>
            )
        }
    }

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setSidebarExpanded(sidebarExpanded ? panel : false)
    }
    const renderContactButtons = (details: ContactDetails) => {

        let telephone, email, twitterHandle, linkedinProfile

        if (details) {
            telephone = details.telephone || ''
            email = details.email || ''
            twitterHandle = details.twitterHandle || ''
            linkedinProfile = details.linkedinProfile || ''
        }

        return (
            <Grid className={classes.contactDetailsContainer}
                  container
                  justify={'flex-start'}
                  alignContent={'center'}
                  spacing={1}
            >
                {
                    telephone && (
                        <Grid item xs={12} sm={6}>
                            <a href={`tel:${telephone}`} className={classes.contactDetailsLink}>
                                <CallIcon fontSize={'large'}/>
                                {telephone}
                            </a>
                        </Grid>
                    )
                }
                {
                    email && (
                        <Grid item xs={12} sm={6}>
                            <a href={`mailto:${email}`} target={'_blank'} className={classes.contactDetailsLink}>
                                <EmailIcon fontSize={'large'}/>
                                {email}
                            </a>
                        </Grid>
                    )
                }
                {
                    barrister.resume && (
                        <Grid item xs={12} sm={twitterHandle || linkedinProfile ? 6 : 12}>
                            <a href={barrister.resume.asset.url} target="_blank" className={classes.contactDetailsLink}>
                                <PictureAsPdfIcon fontSize={'large'}/>
                                Download Résumé
                            </a>
                        </Grid>
                    )
                }
                {
                    twitterHandle && (
                        <Grid item xs={12} sm={6}>
                            <a href={`https://twitter.com/${twitterHandle}`} target={'_blank'}
                               className={classes.contactDetailsLink}>
                                <TwitterIcon fontSize={'large'}/> Twitter
                            </a>
                        </Grid>
                    )
                }
                {
                    linkedinProfile && (
                        <Grid item xs={12} sm={6}>
                            <a href={linkedinProfile} className={classes.contactDetailsLink}>
                                <LinkedInIcon fontSize={'large'}/> LinkedIn
                            </a>
                        </Grid>
                    )
                }
            </Grid>
        )
    }

    const renderTopLevelPracticeAreas = (practiceAreas: PracticeAreas, layout: 'list' | 'csv') => {
        const keys = Object.keys(practiceAreas).sort()
        const pas: { title: string, subPracticeAreas: string[] }[] = keys.map((practiceArea) => {
            return {
                title: practiceAreas[practiceArea].title,
                subPracticeAreas: practiceAreas[practiceArea][practiceArea]?.subPracticeAreas || []
            }
        })
        return pas
            .map((pa, idx) => (
                    pas.length > 1
                    ? <Typography key={idx} variant={'body1'} component={'strong'}>
                        {idx + 1 === pas.length && ' and '}<span
                        className={classes.topLevelPracticeArea}>{pa.title}</span>{idx + 1 === pas.length ? '.' : ', '}
                    </Typography>
                    : <Typography key={idx} variant={'body1'} component={'strong'}>
                        <span className={classes.topLevelPracticeArea}>
                            {pa.title}
                        </span>{idx + 1 === pas.length ? '.' : ', '}
                    </Typography>
                )
            )
    }

    const getPracticeAreaName = (name: string) => {
        switch (name) {
            case 'commercialLaw':
                return 'Commercial Law'
            case 'employmentIndustrial':
                return 'Industrial & Employment Law'
            case 'publicLaw':
                return 'Public Law'
            case 'tortLaw':
                return 'Tort Law'
            case 'criminalLaw':
                return 'Criminal Law'
            case 'intellectualProperty':
                return 'Intellectual Property'
            case 'EMPLOYMENT_INDUSTRIAL':
                return 'Industrial & Employment Law'
            case 'CRIMINAL_LAW':
                return 'Criminal Law'
            case 'PUBLIC_LAW':
                return 'Public Law'
            case 'TORT_LAW':
                return 'Tort Law'
            case 'INTELLECTUAL_PROPERTY':
                return 'Intellectual Property'
            case 'COMMERCIAL_LAW':
                return 'Commercial Law'
        }
    }

    type PracticeAreaNode = {
        [string]: PracticeArea
        key: string
        title: string
    }

    const parsePracticeAreas: (practiceAreasArray: PracticeAreas[]) => PracticeAreas = (practiceAreasArray: PracticeAreas[]) => {
        return practiceAreasArray.map((practiceAreas) => {
            const practiceAreaProps: string[] = Object.keys(practiceAreas)
            let prop = ' '
            practiceAreaProps.some((pa) => {
                if (practiceAreas[pa] !== null) {
                    prop = pa
                    return true
                }
            })
            const practiceAreasWithTitle = {
                subPracticeAreas: practiceAreas[prop],
                title: prop === 'topLevelPracticeArea' ? getPracticeAreaName(practiceAreas.topLevelPracticeArea) : getPracticeAreaName(prop),
                key: prop
            }
            return practiceAreasWithTitle
        }).reduce((p, current, currentIdx) => {
            const newObj = {...p}
            newObj[current.key] = current
            return newObj
        }, {})
    }

    const renderPracticeAreasAndSubPracticeAreas: (practiceAreasArray: PracticeAreas[]) => any = (practiceAreasArray) => {
        const practiceAreas = parsePracticeAreas(practiceAreasArray)
        const practiceAreaProps = Object.keys(practiceAreas)
        const elements = []

        const keys = Object.keys(practiceAreas).sort()
        const pas: { title: string, subPracticeAreas: string[] }[] = keys.map((practiceArea) => {
            return {
                title: practiceAreas[practiceArea].title,
                subPracticeAreas: practiceAreas[practiceArea]?.subPracticeAreas || []
            }
        })
        return (
            <>
                {
                    pas.map((pa, idx) => (
                            <section key={idx}>
                                <Typography variant={'h6'} component={'h3'}>
                                    {pa.title}
                                </Typography>
                                <ul className={classes.subPracticeAreaContainer}>
                                    {
                                        pa.subPracticeAreas &&
                                        pa.subPracticeAreas.subPracticeAreas &&
                                        pa.subPracticeAreas.subPracticeAreas
                                            .map((subPracticeArea, index) => (
                                                    <li key={index}>
                                                        {subPracticeArea}
                                                    </li>
                                                )
                                            )
                                    }
                                </ul>
                            </section>
                        )
                    )
                }
            </>
        )
    }

    const renderPractices = (practices: Practice[], practiceAreas: PracticeAreas) => {
        // const topSixPracticeAreas = practiceAreas.slice(0, 6)
        // const otherPracticeAreas = practiceAreas.slice(6, practiceAreas.length)
        const pas = parsePracticeAreas(practiceAreas)
        return practices && practices.map((practice, idx) => {
            return (
                <section className={classes.practiceWrapper} key={idx}>
                    <section className={classes.practiceAreasContainer}>
                        <Typography variant={'body1'} component={'p'}>
                            {
                                practice.jurisdiction && (
                                    <>
                                        Appears in {practice.jurisdiction} practising
                                        in<br/> {practiceAreas && renderTopLevelPracticeAreas(pas, 'csv')}
                                    </>
                                )
                            }
                        </Typography>
                        <br/>
                        <Typography variant={'body1'} component={'p'}>
                            {
                                practice.directBriefs !== undefined && practice.directBriefs && (
                                    ` Accepts Direct ${practice.electronicBriefs ? `and Electronic` : ''} Briefs. `
                                )
                            }
                            {
                                practice.electronicBriefs !== undefined && !practice.directBriefs && practice.electronicBriefs && (
                                    <>
                                        Accepts Electronic Briefs.
                                    </>
                                )
                            }
                        </Typography>
                    </section>
                </section>
            )
        })
    }

    return (
        <Container maxWidth={'lg'}>
            <Grid container>
                {/* FULL NAME === */}
                <Grid item xs={12} container alignContent={'center'}>
                    <Typography variant={'h1'} component={'h1'}>
                        <span className={classes.barristerNameTitle}>{barrister.preferredName}</span>
                    </Typography>
                </Grid>
            </Grid>
            <Grid container alignContent={'center'}>
                <Grid item xs={12}>
                    <Typography style={{padding: '0 0 1em 0'}} variant={'h6'} component={'p'}>
                        {barrister.practices[0] && barrister.practices[0].admissionToPracticeYear && (
                            <span>Admitted {barrister.practices[0].admissionToPracticeYear} | </span>
                        )}
                        {
                            barrister.practices[0] && barrister.practices[0].joinedBarYear && (
                                <span>Bar {barrister.practices[0].joinedBarYear} </span>
                            )
                        }
                        {barrister.practices[0] && barrister.practices[0].takenSilk ? ` | Silk ${barrister.practices[0].silkYear}` : ''}
                    </Typography>
                    {
                        !barrister._rawPracticeDescription && barrister.practices && renderPractices(barrister.practices, barrister.practiceAreas)
                    }
                    {
                        barrister._rawPracticeDescription && (
                            <PortableText blocks={barrister._rawPracticeDescription}/>
                        )
                    }
                </Grid>
            </Grid>
            <Grid container className={classes.barristerInformationContainer} spacing={4}>
                <Grid item sm={12}>
                    {renderContactButtons(barrister.contactDetails)}
                    <Divider style={{marginTop: '1.8rem'}} variant={'middle'}/>
                </Grid>
                <Grid item className={classes.biographyContainer}>
                    <PortableText blocks={barrister._rawBiography}/>
                    {
                        barrister.languages.length > 0 && (
                            <p>Languages: {barrister.languages.map((lang, idx) => idx + 1 === barrister.languages.length ? `${lang}.` : `${lang}, `)}</p>
                        )
                    }
                </Grid>

                <Grid item lg={12} className={classes.sideBarContainer}>
                    {!barrister._rawPracticeDescription && barrister.practiceAreas && (
                        <Accordion expanded={sidebarExpanded === 'practiceAreas'}
                                   onChange={handleChange('practiceAreas')}>
                            <AccordionSummary
                                expandIcon={barrister.cases.length > 0 && barrister.memberships.length > 0 &&
                                <ExpandMoreIcon/>}
                                aria-controls="practiceAreas-content"
                                id="practiceAreas-header"
                            >
                                <Typography variant={'h5'} component={'h2'}>Practice Areas</Typography>
                            </AccordionSummary>
                            <AccordionDetails className={classes.practiceAreasAccordion}>
                                {
                                    renderPracticeAreasAndSubPracticeAreas(barrister.practiceAreas)
                                }
                            </AccordionDetails>
                        </Accordion>
                    )}
                    {
                        barrister.cases.length > 0 && (
                            <Accordion expanded={sidebarExpanded === 'cases'} onChange={handleChange('cases')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="cases-content"
                                    id="cases-header"
                                >
                                    <Typography variant={'h5'}
                                                component={'h2'}>
                                        {
                                            barrister.barristerGroup === 'academicMember' ? 'Publications' : 'Cases'
                                        }
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ul className={classes.caseList}>
                                        {
                                            barrister.cases && barrister.cases.map((caseItem: Case, idx) => (
                                                    <li key={idx} className={classes.caseLinks}>
                                                        {
                                                            !caseItem.link && !caseItem.decisionFile?.asset.url ? (
                                                                <p style={{margin: 0}}>{caseItem.citation}</p>
                                                            ) : null
                                                        } {
                                                        caseItem.link && caseItem.citation && (
                                                            <a href={caseItem.link}
                                                               target={'_blank'}>{caseItem.citation}</a>
                                                        )
                                                    }
                                                        {
                                                            caseItem.decisionFile && caseItem.decisionFile.asset.url ? (
                                                                <Link to={caseItem.decisionFile.asset.url}> (PDF)</Link>
                                                            ) : null
                                                        }
                                                    </li>
                                                )
                                            )
                                        }
                                    </ul>
                                </AccordionDetails>
                            </Accordion>
                        )
                    }
                    {
                        barrister && barrister.memberships.length > 0 && (
                            <Accordion expanded={sidebarExpanded === 'memberships'}
                                       onChange={handleChange('memberships')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="memberships-content"
                                    id="memberships-header">
                                    <Typography variant={'h5'} component={'h2'}>Memberships</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ul className={classes.caseList}>
                                        {
                                            barrister.memberships && barrister.memberships.map((membership: string, idx) => (
                                                    <li key={idx} className={classes.caseLinks}>
                                                        {membership}
                                                    </li>
                                                )
                                            )
                                        }
                                    </ul>
                                </AccordionDetails>
                            </Accordion>
                        )
                    }
                </Grid>
            </Grid>
        </Container>
    )
}

export default BarristerProfile
